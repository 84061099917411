export default function Search() {
  const searchBar = document.querySelectorAll(`.Search`)[0];
  const searchButton = document.querySelectorAll(`.Menu__search`)[0];
  let curState = false;

  function keyHandler(e) {

    const focusableEls = Array.from(searchBar.querySelectorAll(`iframe, iframe *, [tabindex="0"], a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])`));
    const firstFocusableEl = focusableEls[0];
    const lastFocusableEl = focusableEls[focusableEls.length - 1];

    let isEscape = (e.key === `Escape` || e.key === `Esc` || e.keyCode === 27);

    if (e.shiftKey) /* shift + tab */ {
      if (document.activeElement === firstFocusableEl) {
        lastFocusableEl.focus();
        e.preventDefault();
      }
    } else /* tab */ {
      if (document.activeElement === lastFocusableEl) {
        firstFocusableEl.focus();
        e.preventDefault();
      }
    }

    if (isEscape && curState) {
      toggleSearchBar();
    }

  }

  const toggleSearchBar = function () {
    curState = !curState
    searchBar.classList.toggle(`Search--is-active`);
    searchButton.classList.toggle(`Menu__search--is-active`);
    if (curState) {
      searchBar.querySelectorAll(`input`)[0].focus();
      // searchBar.addEventListener(`keydown`, keyHandler);
    } else {
      searchButton.focus();

      // searchBar.removeEventListener(`keydown`, keyHandler, {
      //   passive: true
      // });
    }
    searchButton.setAttribute(`aria-expanded`, curState);
  };

  // const closeNav = function() {
  //   curState = false;
  //   searchButton.focus();
  //   searchBar.classList.remove([`Search--is-active`]);
  //   searchBar.removeEventListener(`keydown`, keyHandler, {
  //     passive: true
  //   });
  //   searchButton.setAttribute(`aria-expanded`, curState);
  // }


  if (searchButton) {
    searchButton.addEventListener(`click`, toggleSearchBar);
  }

  // const navItems = searchBar.querySelectorAll(`a`);
  // navItems.forEach(item => item.addEventListener(`click`, closeNav));

}

// EOF
